$paragrap-size: 1.2rem;
$paragrap-size-mobile: 1rem;

@font-face {
  font-family: "Nasa";
  src: local("GoldmanBold"),
    url("./fonts/nasalization-rg.otf") format("truetype");
}

body {
  background-color: #000000 !important;
  color: #4e4e4e !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1e1e1e;
}

.color-primary {
  color: #7757ff;
}

a {
  color: #1e1e1e;
  transition: all 0.5s ease-in-out;
}

a:hover {
  color: #0078ff;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}

.p-r {
  position: relative;
}

.color-a {
  color: #0078ff;
}

.color-d {
  color: #f5f5f5;
}

.color-text-a {
  color: #4e4e4e;
}

.box-shadow-a,
.button:hover {
  box-shadow: 0 0 0 4px #cde1f8;
}

.bg-image {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
}

.overlay-mf {
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: 0.7;
}

.evening-background {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgb(25, 14, 85) 100%
  );
}

.morning-background {
  background: linear-gradient(
    180deg,
    rgb(25, 14, 85) 0%,
    rgb(161, 32, 247) 100%
  );
}

.dawn-background {
  background: radial-gradient(
    circle at center bottom,
    #c2ce1e 20%,
    #f74b20 40%,
    #a120f7 60%,
    #a120f7 100%
  );
}

.paralax-mf {
  position: relative;
}

.display-table {
  width: 100%;
  height: 100%;
  display: table;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

/* Sections */

.sect-4 {
  padding: 4rem 0;
}

.sect-pt4 {
  padding-top: 4rem;
}

/* Title Left */

.title-box-2 {
  margin-bottom: 2rem;
}

.title-left {
  color: white;
  font-family: "Nasa", Courier, monospace;
  font-size: 2rem;
  position: relative;
}

.title-left:before {
  content: "";
  position: absolute;
  height: 3px;
  background-color: #7757ff;
  width: 100px;
  bottom: -8px;
}

.badge {
  padding: 0.5em 0.75em;
  color: white !important;
  background-color: #3d0641 !important;
}

.vertical-timeline-element-content .vertical-timeline-element-content-arrow {
  border-right: 7px solid #fff;
}

.vertical-timeline-element-date {
  font-size: 0.89rem !important;
  font-weight: 700 !important;
  opacity: 1 !important;
}

/* Socials */

.socials {
  padding-bottom: 1.5rem;
}

.socials ul li {
  display: inline-block;
}

.socials .ico-circle {
  height: 40px;
  width: 40px;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 1.4;
  margin: 0 15px 0 0;
  box-shadow: 0 0 0 3px #7757ff;
  transition: all 500ms ease;
}

.socials .ico-circle:hover {
  background-color: #7757ff;
  color: #fff;
  box-shadow: 0 0 0 3px #d7cdf8;
  transition: all 500ms ease;
}

/* Ul resect */

.ul-resect,
.socials ul,
.list-ico,
.blog-wrapper .post-meta ul,
.box-comments .list-comments,
.widget-sidebar .list-sidebar,
.widget-tags ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.list-ico {
  line-height: 2;
}

.list-ico span {
  color: #7757ff;
  margin-right: 10px;
}

/* Ico Circle */

.ico-circle {
  height: 100px;
  width: 100px;
  font-size: 2rem;
  border-radius: 50%;
  line-height: 1.55;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 0 0 10px #7757ff;
  display: block;
}

/* Back to top button */

.back-to-top {
  position: fixed;
  display: none;
  background: #7757ff;
  color: #fff;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 16px;
  border-radius: 3px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 11;
}

.back-to-top i {
  padding-top: 12px;
  color: #fff;
}

/* Prelaoder */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: black;
}

.moon {
  position: fixed;

  width: 80px;
  max-width: 15vw;
  opacity: 1;
  animation-name: moon-animation;
  animation-duration: 1ms;
  animation-timeline: scroll();
}

@keyframes moon-animation {
  from {
    top: calc(75px);
    right: 100px;
  }
  to {
    right: calc(100vw - 100px);
    top: calc(60vh);
  }
}

/*
NAVBAR
*/

#logo-navbar {
  height: 100%;
  margin-right: 1rem;
}

#navbarDefault {
  margin: 1rem;
  transition: all 500ms ease;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0 !important;
}

.navbar-b.navbar-reduce .nav-item {
  position: relative;
  padding-right: 10px;
  padding-left: 0;
  border-bottom: solid 1px #e3e3e3;
}

.navbar-b.navbar-reduce #navbarDefault {
  margin-top: 2rem;
}

.navbar-b {
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-b.navbar-reduce {
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
}

.navbar-b.navbar-trans .nav-item,
.navbar-b.navbar-reduce .nav-item {
  position: relative;
  padding-right: 10px;
  padding-left: 0;
}

.navbar-b.navbar-trans .nav-link,
.navbar-b.navbar-reduce .nav-link {
  color: #fff !important;
  cursor: pointer;
  font-weight: 300;
  font-family: "Nasa", Courier, monospace;
}

.navbar-b.navbar-trans .nav-link:before,
.navbar-b.navbar-reduce .nav-link:before {
  content: "";
  position: absolute;
  margin-left: 0px;
  width: 0%;
  bottom: 0;
  left: 0;
  height: 2px;
  transition: all 500ms ease;
}

.navbar-b.navbar-trans .nav-link:hover,
.navbar-b.navbar-reduce .nav-link:hover {
  color: #1b1b1b;
}

.navbar-b.navbar-trans .nav-link:hover:before,
.navbar-b.navbar-reduce .nav-link:hover:before {
  width: 35px;
}

.navbar-b.navbar-trans .show > .nav-link:before,
.navbar-b.navbar-trans .active > .nav-link:before,
.navbar-b.navbar-trans .nav-link.show:before,
.navbar-b.navbar-trans .nav-link.active:before,
.navbar-b.navbar-reduce .show > .nav-link:before,
.navbar-b.navbar-reduce .active > .nav-link:before,
.navbar-b.navbar-reduce .nav-link.show:before,
.navbar-b.navbar-reduce .nav-link.active:before {
  width: 35px;
}

.navbar-b.navbar-trans .nav-link:before {
  background-color: #fff;
}

.navbar-b.navbar-trans .nav-link:hover {
  color: #fff;
}

.navbar-b.navbar-trans .show > .nav-link,
.navbar-b.navbar-trans .active > .nav-link,
.navbar-b.navbar-trans .nav-link.show,
.navbar-b.navbar-trans .nav-link.active {
  color: #fff;
}

.navbar-b.navbar-reduce {
  transition: all 0.5s ease-in-out;
  background-color: rgba(9, 9, 9, 0.9);
  color: #7757ff;
}

.navbar-b.navbar-reduce .nav-link {
  // color: white;
}

.navbar-b.navbar-reduce .nav-link:before {
  background-color: #7757ff;
}

.navbar-b.navbar-reduce .nav-link:hover {
  color: #ae9dfa;
}

.navbar-b.navbar-reduce .show > .nav-link,
.navbar-b.navbar-reduce .active > .nav-link,
.navbar-b.navbar-reduce .nav-link.show,
.navbar-b.navbar-reduce .nav-link.active {
  color: #7757ff !important;
}

.navbar-b.navbar-reduce .navbar-brand {
  color: #7757ff;
}

.navbar-b .navbar-brand {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
}

.navbar-b .navbar-nav .dropdown-item.show .dropdown-menu,
.navbar-b .dropdown.show .dropdown-menu,
.navbar-b .dropdown-btn.show .dropdown-menu {
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  visibility: visible !important;
}

.navbar-b .dropdown-menu {
  margin: 1.12rem 0 0;
  border-radius: 0;
}

.navbar-b .dropdown-menu .dropdown-item {
  padding: 0.7rem 1.7rem;
  transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item:hover {
  background-color: #7757ff;
  color: #fff;
  transition: all 500ms ease;
}

.navbar-b .dropdown-menu .dropdown-item.active {
  background-color: #7757ff;
}

/* Hamburger Navbar */

.navbar-b .navbar-toggler {
  position: absolute;
  min-height: 35px;
  right: 1.5rem;
  top: 1rem;
  background-color: transparent;
  transition: all 500ms ease;
}

.navbar-b.navbar-reduce .navbar-toggler {
  top: 2rem;
  background: #3d0641;
  color: white;
  border: 1px solid white;

  transition: all 500ms ease;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0;
}

.navbar-toggler span {
  display: block;
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  left: 0;
  opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: -webkit-transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

/*
INTRO
*/

.intro {
  height: 100vh;
  position: relative;
  color: #fff;
  z-index: 1;
}

.intro .intro-content {
  text-align: center;
  position: absolute;
}

.intro .overlay-intro {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0px;
  padding: 0;
  height: 100%;
  width: 100%;
  opacity: 0.9;
}

.intro .intro-title {
  color: #fff;
  font-weight: 600;
  font-size: 3rem;
  letter-spacing: -0.06em;
}

.hello {
  color: black;
  display: block;
  position: relative;
  padding: 0 12px;
  font-family: "Source Code Pro", monospace;
}

.hello:before {
  content: "¡Hola!, Soy Edgard Rojas";
  position: absolute;
  inset: 0;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background-clip: text !important;
  background: linear-gradient(
    180deg,
    rgb(42, 77, 250) 0%,
    rgb(160, 87, 255) 100%
  );
}

.intro .intro-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

/* .intro .text-slider-items {
  font-weight: bolder;
} */

.intro-single {
  height: 350px;
}

.intro-single .intro-content {
  margin-top: 30px;
}

.intro-single .intro-title {
  text-transform: uppercase;
  font-size: 3rem;
}

.intro-single .breadcrumb {
  background-color: transparent;
  color: #0078ff;
}

.intro-single .breadcrumb .breadcrumb-item:before {
  color: #cde1f8;
}

.intro-single .breadcrumb .breadcrumb-item.active {
  color: #cde1f8;
}

.intro-single .breadcrumb a {
  color: #fff;
}

/*
ABOUT
*/

.about-me {
  .lead {
    font-weight: 500;
    font-size: $paragrap-size-mobile;
  }
}

.about-mf .box-shadow-full {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.about-mf .about-img {
  margin-bottom: 2rem;
}

.about-mf .about-img img {
  margin-left: 10px;
}

.skill-mf span {
  color: white;
}

.skill-mf .progress {
  background-color: #3d0641;
  margin: 0.5rem 0 1.2rem 0;
  border-radius: 2px;
  height: 0.7rem;
}

.skill-mf .progress .progress-bar {
  height: 0.7rem;
  background-color: #7757ff;
  transition: width;
  transition-duration: 2s;
  transition-timing-function: ease-in;
}

@keyframes progressAnimationStrike {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.vertical-timeline-element-content {
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.199) !important;
}

.timeline {
  width: 100% !important;
}

/*
FOOTER
*/

footer {
  background: transparent;
  z-index: 1;
  max-width: 100vw;
  position: absolute;
  bottom: 5px;
  min-width: 100%;
}

footer .copyright {
  text-align: center;
  color: white;
}

/*
CONTACT 
*/

#contact {
  min-height: calc(100vh - 64px);
}

button#contact {
  height: 4em;
  width: 15em;
  border: none;
  border-radius: 40px;
  background-color: #fff;
}

button#contact span {
  z-index: 1;
  display: inline-block;
  background-color: rgb(67, 67, 255);
  height: 3em;
  width: 11.5em;
  border-radius: 25px;
  color: #fff;
  line-height: 55px;
  font-size: 18px;
  letter-spacing: 3px;
  transition: all 0.5s;
}

button#contact .container {
  z-index: -1;
  width: 0;
  position: relative;
  display: flex;
  justify-content: center;
  transform: translateY(-50px);
  transition: all 0.4s;
}

button#contact .container svg {
  padding: 0 10px;
}

button:hover .container {
  z-index: 2;
  width: 100%;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: rgb(255, 255, 255);
}

.form-transparent {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: white;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: 0;
}

.form-transparent:focus,
.form-transparent:hover {
  background: rgba(0, 0, 0, 0.062);
  border-color: #9178ff;
}

.mountains {
  z-index: 1;
  max-width: 100vw;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

#container-form {
  padding-top: 4rem;
  padding-bottom: 4rem;
  justify-content: center;

  color: white;
  position: relative;
  z-index: 2;
  margin-bottom: 3rem;
  .title-left {
    color: white;
  }

  .box-shadow-full {
    background-color: #09090933;
  }
}

#sendmessage {
  color: #0078ff;
  border: 1px solid #0078ff;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#sendmessage.show,
#errormessage.show,
.show {
  display: block;
}

.validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

/*
BUTTON
*/

.button {
  display: inline-block;
  padding: 0.3rem 0.6rem;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  border-radius: 0.3rem;
  border: 1px solid transparent;
  transition: all 500ms ease;
  cursor: pointer;
  background-color: #3915b1 !important;
}

.button-big {
  padding: 0.9rem 2.3rem;
  font-size: 1.2rem;
}

.button-rouded {
  border-radius: 5rem;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.container {
  z-index: 1;
}

section {
  padding: 2rem 0rem;
  z-index: 2;
}

.sect-mt4 {
  padding-bottom: 0;
}

.box {
  padding: 2rem 2rem;
  background-color: rgba(9, 9, 9, 0.66);
  border: 1px solid white;
  position: relative;
  z-index: 2;
  color: white;
  margin-bottom: 2rem;
  border-radius: 4px;
  .more-info {
    margin-bottom: 1rem;
  }

  .paragraph {
    font-weight: 500;
    font-size: $paragrap-size-mobile;
    display: inline;
    color: white;
  }

  .title-left {
    color: white;
  }
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  display: none;
  transition: visibility 0s 0.5s, opacity 0.5s linear;
}

.copyright-box {
  font-size: 1.2em;
  text-align: center;
  color: white;
}

@media (min-width: 577px) {
  .counter-box {
    margin-bottom: 1.8rem;
  }
}

@media (min-width: 767px) {
  .box {
    .paragraph {
      font-size: $paragrap-size;
    }
  }
  .about-me {
    .lead {
      font-size: $paragrap-size;
    }
  }

  .about-mf .box-pl2 {
    margin-top: 3rem;
    padding-left: 0rem;
  }

  .contact-mf .box-pl2 {
    margin-top: 3rem;
    padding-left: 0rem;
  }

  .navbar-toggler {
    max-height: 0px;
  }
}

@media (min-width: 768px) {
  .box-shadow-full {
    padding: 3rem;
  }

  .navbar-b.navbar-trans .nav-item,
  .navbar-b.navbar-reduce .nav-item {
    padding-left: 10px;
    border-bottom: 0px;
  }

  .navbar-b.navbar-trans .nav-link:before,
  .navbar-b.navbar-reduce .nav-link:before {
    margin-left: 18px;
  }

  .intro .intro-title {
    font-size: 4.5rem;
  }

  .intro .intro-subtitle {
    font-size: 2.5rem;
  }

  .intro-single .intro-title {
    font-size: 3.5rem;
  }

  .back-to-top {
    bottom: 15px;
  }

  .bg-image {
    background-attachment: scroll;
  }

  .box {
    padding: 2rem 4rem;
  }

  #navbarDefault {
    margin: 0.5rem;
  }

  .navbar-b.navbar-reduce #navbarDefault {
    margin-top: 1rem;
  }
}

.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
  visibility: visible;
  animation: bounce-in-bottom 0.6s ease-in both !important;
  background-color: rgba(9, 9, 9, 0.66);
  color: white;
  border: 1px solid white;
}

.bounce-up {
  animation: bounce-in-bottom 0.6s ease-in both;
}

@keyframes bounce-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
